import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";
import { Link } from "react-router-dom";
import './news.css'

const NewsAndEvents = () => {
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/newsEvents.php");
      if (response?.status === 200) {
        const uniqueData = response.data.reduce((acc, current) => {
          const isExisting = acc.some(
            (item) =>
              item.title.trim().toLowerCase() ===
                current.title.trim().toLowerCase() &&
              item.event_date === current.event_date
          );
          if (!isExisting) {
            acc.push(current);
          }
          return acc;
        }, []);

        setNewsData(uniqueData);
        console.log(uniqueData);
      }
    } catch (error) {
      setNewsData([]);
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  if (newsData === null) {
    return (
      <>
        <h2>Loading....</h2>
      </>
    );
  }

  return (
    <>
      <CommonHeader heading={"News and Events"} />
      <div className="container">
        <section className="news-box top-margin">
          <div className="container">
            <div className="row">
              {newsData.length > 0 ? (
                newsData.map((obj, ind) => (
                  <div className="col-lg-4 col-md-4 col-sm-12" key={ind}>
                    <div className="newsBox">
                      <div className="thumbnail">
                        {obj.images && obj.images.length > 0 ? (
                          <figure>
                            <img
                              src={`${axios.defaults.baseURL}/Images/${obj.images[0]}`}
                              alt="Event"
                              width="300"
                              height="200"
                              className="imageShadow"
                            />
                          </figure>
                        ) : (
                          <div>No image or video found</div>
                        )}
                        <div className="caption maxheight2">
                          <div className="box_inner">
                            <div className="box">
                              <p className="title">
                                <h5>{obj.title}</h5>
                              </p>
                              <p>
                                {obj.description
                                  .split(" ")
                                  .slice(0, 30)
                                  .join(" ")}
                              </p>
                              <p>
                                <Link
                                  to={`/aboutnewsevents/${obj.title.toLowerCase()}`}
                                >
                                  <em>More...</em>
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span>No news or events found.</span>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewsAndEvents;
