import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const NewCourse = () => {
  const [newCoursesData, setNewCoursesData] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/courses.php");

      if (response?.status === 200) {
        const data = response.data;

        // Filter data based on course type
        const newCourses = data.filter((course) => course.course_type === 1);

        // Set state variables accordingly
        setNewCoursesData(newCourses);
      }
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  return (
    <>
      {newCoursesData?.length > 0 && (
        <div className="container">
          <section class="news-box top-margin">
            <div class="container">
              <h2>
                <span>New Courses</span>
              </h2>
              <div class="row">
                {newCoursesData !== null ? (
                  newCoursesData.map((obj, ind) => {
                    return (
                      <div class="col-lg-4 col-md-4 col-sm-12" key={ind}>
                        <div class="newsBox">
                          <div class="thumbnail">
                            {obj.course_type === 1 && obj.video_path ? ( // Check if course type is 1 and video path is available
                              <figure>
                                <video width="300" height="200" controls>
                                  <source
                                    src={`${axios.defaults.baseURL}/Videos/${obj.video_path}`}
                                    type="video/mp4"
                                  />
                                </video>
                              </figure>
                            ) : obj.course_type === 1 && obj.image_path ? ( // Check if course type is 1 and image path is available
                              <figure>
                                <img
                                  src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                                  alt="Course"
                                  width="300"
                                  height="200"
                                />
                              </figure>
                            ) : (
                              <div>No image or video found</div> // Render placeholder message
                            )}
                            <div class="caption maxheight2">
                              <div class="box_inner">
                                <div class="box">
                                  <p class="title">
                                    <h4>{obj.title}</h4>
                                  </p>
                                  <p>
                                    {obj.description
                                      .split(" ")
                                      .slice(0, 30)
                                      .join(" ")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>Loading ....</span>
                )}
                <div className="col-md-12 text-right">
                  <Link to="/moreextracourses">
                    <em>Know More...</em>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default NewCourse;
