import React, { useState, useEffect } from "react";
import logo from "../../Logo/ramakids logo.png";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./navbar.css";

const Navbar = () => {
  const [navData, setNavData] = useState([]);
  const location = useLocation();
  const { mmid, smid } = useParams();

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/mainMenuNav.php");
      if (response?.status === 200) {
        // const filteredData = response.data.filter(
        //   (obj) => obj.main_menu_id === mmid && obj.sub_menu_id === smid
        // );
        // setAboutData(filteredData);
        // console.log(response.data);
        setNavData(response.data);
      }
    } catch (error) {
      setNavData([]);
      console.error("Error fetching data:", error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <>
      <div className="navbar navbar-inverse fixingnavbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-sm-2">
              <div className="navbar-header text-center">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "flexStart",
                    alignItems: "center",
                    marginLeft: "11%",
                    height: "6rem",
                  }}
                  to="/"
                >
                  <img src={logo} alt="Ramakrishna Website" id="navbarImage" />
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-sm-10">
              <div className="navbar-collapse collapse">
                <ul className="nav navbar-nav pull-right mainNav">
                  <li className={isActive("/") ? "active" : ""}>
                    <Link to="/" className="adjustPadding">
                      Home
                    </Link>
                  </li>
                  <li className="dropdown">
                    <Link
                      to="#"
                      className={`dropdown-toggle ${
                        isActive("/aboutCampus") ||
                        isActive("/facilities") ||
                        isActive("/schoolTour")
                          ? "active"
                          : ""
                      } adjustPadding`}
                      data-toggle="dropdown"
                    >
                      Campus <b className="caret"></b>
                    </Link>
                    <ul
                      className="dropdown-menu adjustPadding"
                      style={{ backgroundColor: "rgb(255 204 0)" }}
                    >
                      <li>
                        <Link to="/aboutCampus" className="customeUl">About Campus</Link>
                      </li>
                      <li>
                        <Link to="/facilities" className="customeUl">Facilities</Link>
                      </li>
                      <li>
                        <Link to="/schoolTour" className="customeUl">School Tour</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle adjustPadding"
                      data-toggle="dropdown"
                    >
                      Admission <b className="caret"></b>
                    </Link>
                    <ul className="dropdown-menu" style={{ backgroundColor: "rgb(255 204 0)" }}>
                      <li>
                        <Link to="/admissionProcess" className="customeUl">Admission Process</Link>
                      </li>
                      <li>
                        <Link to="/admissionForm" className="customeUl">Admission Form</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle adjustPadding"
                      data-toggle="dropdown"
                    >
                      Academics <b className="caret"></b>
                    </Link>
                    <ul className="dropdown-menu" style={{ backgroundColor: "rgb(255 204 0)" }}>
                      <li>
                        <Link className="customeUl" to="/curriculamdetails">Curriculam Details</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle adjustPadding"
                      data-toggle="dropdown"
                    >
                      Activities <b className="caret"></b>
                    </Link>
                    <ul className="dropdown-menu" style={{ backgroundColor: "rgb(255 204 0)" }}>
                      <li>
                        <Link className="customeUl" to="/newsEvents">News & Events</Link>
                      </li>
                      <li>
                        <Link className="customeUl" to="/coCurricular">Co Curricular Initiative</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle adjustPadding"
                      data-toggle="dropdown"
                    >
                      Parent Zone <b className="caret"></b>
                    </Link>
                    <ul className="dropdown-menu" style={{ backgroundColor: "rgb(255 204 0)" }}>
                      {/* <li>
                    <Link to="/mealPlan">Meal Plan</Link>
                  </li> */}
                      <li>
                        <Link className="customeUl" to="/circular">Circular</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/gallery" className="adjustPadding">
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact1" className="adjustPadding">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/referAfriend" className="adjustPadding">
                      Refer
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="navbar-header text-center">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link className="navbar-brand" to="index.html">
              <img
                src={logo}
                alt="Ramakrishna Website"
                style={{ height: "8rem", width: "10rem" }}
              />
            </Link>
          </div>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav pull-right mainNav">
              {navData
                .reduce((acc, menuItem) => {
                  const existingMainMenu = acc.find(
                    (item) => item.menu_name === menuItem.menu_name
                  );
                  if (existingMainMenu) {
                    // If main menu already exists, add sub-menu to it
                    existingMainMenu.sub_menus.push(menuItem);
                  } else {
                    // If main menu doesn't exist, create a new main menu entry
                    acc.push({ ...menuItem, sub_menus: [] });
                  }
                  return acc;
                }, [])
                .map((mainMenu, index) => (
                  <li key={index}>
                    {mainMenu.sub_menus.length ? (
                      <div className="dropdown">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {mainMenu.menu_name} <b className="caret"></b>
                        </Link>
                        <ul className="dropdown-menu">
                          {mainMenu.sub_menus.map((subMenu, subIndex) => (
                            <li key={subIndex}>
                              <Link
                                to={`/${mainMenu.menu_name
                                  .toLowerCase()
                                  .replace(/\s+/g, "")}/${subMenu.sub_menu_name
                                  .toLowerCase()
                                  .replace(/\s+/g, "")}?mmid=${
                                  mainMenu.id
                                }&smid=${subMenu.id}`}
                              >
                                {subMenu.sub_menu_name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Link
                        to={`/${mainMenu.menu_name
                          .toLowerCase()
                          .replace(/\s+/g, "")}?mmid=${mainMenu.id}&smid=${
                          mainMenu.id
                        }`}
                      >
                        {mainMenu.menu_name}
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div> */}

      <Outlet />
    </>
  );
};

export default Navbar;
