import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";

const AboutCampus = (props) => {
  const [about, setAbout] = useState(null);
  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      const data = response.data;
      const abouData = data.filter((obj) => obj.content_type === 6);
      setAbout(abouData);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  return (
    <>
      <CommonHeader heading={"About Us"} />
      <div className="container">
        <section class="container">
          <div class="row">
            {about !== null ? (
              about.map((obj, ind) => {
                return (
                  <section class="col-sm-12 maincontent">
                    <h3>{obj.title}</h3>
                    <p>
                      <img
                        src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                        alt="No Image Data Found"
                        class={`img-rounded ${
                          ind % 2 === 0 ? "pull-right" : "pull-left"
                        }`}
                        width="300"
                      />
                      {renderContent(obj.description)}
                    </p>
                  </section>
                );
              })
            ) : (
              <>
                <h3>Loading...</h3>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutCampus;
