import React from "react";

const Videos = () => {
  return (
    <>
      {/* <!-- container --> */}
      <section className="container">
        <div className="row">
          <div className="col-md-12">
            <section
              id="portfolio"
              className="page-section section appear clearfix"
            >
              <br />
              <br />
              <p>
                At lorem Ipsum available, but the majority have suffered
                alteration in some form by injected huffered altehe majority
                have suffered alteration in some form by injected huffered
                alteration in some form by injected humour.uffered alteration in
                some form by injected h
                <br />
                <br />
              </p>

              <div className="row">
                <nav id="filter" className="col-md-12 text-center">
                  <ul>
                    <li>
                      <a
                        href="#"
                        className="current btn-theme btn-small"
                        data-filter="*"
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="btn-theme btn-small"
                        data-filter=".webdesign"
                      >
                        Development
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="btn-theme btn-small"
                        data-filter=".photography"
                      >
                        Designing
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="btn-theme btn-small"
                        data-filter=".print"
                      >
                        Tools
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="portfolio-items isotopeWrapper clearfix"
                      id="3"
                    >
                      <article className="col-sm-4 isotopeItem webdesign">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img1.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img1.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem photography">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img2.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img2.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem photography">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img3.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img3.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem print">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img4.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img4.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem photography">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img5.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img5.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem webdesign">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img6.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="assets/images/portfolio/img6.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem print">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img7.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="images/portfolio/img7.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem photography">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img8.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="images/portfolio/img8.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>

                      <article className="col-sm-4 isotopeItem print">
                        <div className="portfolio-item">
                          <img src="assets/images/portfolio/img9.jpg" alt="" />
                          <div className="portfolio-desc align-center">
                            <div className="folio-info">
                              <a
                                href="images/portfolio/img9.jpg"
                                className="fancybox"
                              >
                                <h5>Project Title</h5>
                                <i className="fa fa-link fa-2x"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <!-- /container --> */}
    </>
  );
};

export default Videos;
