import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { Link } from "react-router-dom";

const Slider = () => {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const gettingData = async () => {
      try {
        const response = await axios.get("/slider.php");
        if (response.status === 200) {
          // console.log(response.data);
          setSliderData(response.data);
        }
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    };

    gettingData();
  }, []);

  if (sliderData.length === 0) {
    return <h3>Loading...</h3>;
  }

  return (
    <header id="head">
      <div className="row">
        <div className="container-fluid">
          <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
            {sliderData.map((image, index) => (
              <div key={index} style={{ position: "relative" }}>
                <img
                  src={`${axios.defaults.baseURL}/Images/${image.image_path}`}
                  alt={image.alt}
                  style={{
                    width: "100%",
                    height: "60rem",
                    objectFit: "fill",
                    filter: "grayscale(20%)",
                  }}
                  className="fake_hover"
                />
                <div className="heading-text">
                  <a
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "white",
                      // display:"flex",
                      // justifyContent:"start",
                      // flexDirection:"column",
                      // alignItems:"start"
                    }}
                    href={image.url}
                    target="_blank"
                  >
                    <h1 className="animated flipInY delay1">{image.title}</h1>
                    <p className="carousel-text">{image.description}</p>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </header>
  );
};

export default Slider;
