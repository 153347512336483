// import logo from "./logo.svg";
// import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import MainSection from "./Component/MainSection/MainSection";
import Contact from "./Component/Contact/Contact";
import Videos from "./Component/Videos/Videos";
import Gallery from "./Component/Gallery/Gallery";
import Marquee from "./Component/Header/Marquee";
import axios from "axios";
import { useEffect, useState } from "react";
import AboutCampus from "./Component/Campus/AboutCampus";
import Facilities from "./Component/Campus/Facilities";
import SchoolTour from "./Component/Campus/SchoolTour";
import AdmissionProcess from "./Component/Admission/AdmissionProcess";
import AdmissionForm from "./Component/Admission/AdmissionForm";
import CirriculamDetails from "./Component/AcadmicPrograms/CirriculamDetails";
import NewsAndEvents from "./Component/CoCircularActivities/NewsAndEvents";
import Circular from "./Component/ParentZone/Circular";
import ReferAfriend from "./Component/Contact/ReferAfriend";
import MoreExtraFeature from "./Component/MainSection/MoreExtraFeature";
import AboutExtraFeature from "./Component/MainSection/AboutExtraFeature";
import MoreExtraCourses from "./Component/MainSection/MoreExtraCourses";
import AboutCourse from "./Component/MainSection/AboutCourse";
import AboutNewsEvents from "./Component/CoCircularActivities/AboutNewsEvents";
import CoCurricularInitiative from "./Component/CoCircularActivities/CoCurricularInitiative";

function App() {
  const [allSubMenu, setAllSubMenu] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/mainMenuNav.php");

    if (response?.status === 200) {
      // console.log(response.data);
      setAllSubMenu(response.data);
    } else {
      setAllSubMenu(null);
    }
  };

  return (
    <>
      <Marquee />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<MainSection />} />
        <Route exact path="/aboutCampus" element={<AboutCampus />} />
        <Route exact path="/facilities" element={<Facilities />} id={10} />
        <Route
          exact
          path="/schoolTour"
          element={<SchoolTour mmid={10} smid={9} />}
        />
        <Route
          exact
          path="/admissionProcess"
          element={<AdmissionProcess mmid={11} smid={10} />}
        />
        <Route
          exact
          path="/admissionForm"
          element={<AdmissionForm mmid={11} smid={11} />}
        />
        <Route
          path="/curriculamdetails"
          element={<CirriculamDetails mmid={12} smid={12} />}
        />
        <Route
          path="/newsEvents"
          element={<NewsAndEvents mmid={13} smid={13} />}
        />
        <Route path="/circular" element={<Circular />} />
        <Route exact path="/contact1" element={<Contact />} />
        <Route exact path="/video" element={<Videos />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route path="/referAfriend" element={<ReferAfriend />} />

        {/* Extra Features */}
        <Route path="/moreextrafeature" element={<MoreExtraFeature />} />
        <Route path="/aboutextrafeature/:id" element={<AboutExtraFeature />} />

        {/* Extra Course */}
        <Route path="/moreextracourses" element={<MoreExtraCourses />} />
        <Route path="/aboutcourse/:id" element={<AboutCourse />} />

        {/* News and Events */}
        <Route path="/aboutnewsevents/:head" element={<AboutNewsEvents />} />
        <Route path="/coCurricular" element={<CoCurricularInitiative />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
