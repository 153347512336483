import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";
import "./Circular.css";

const Circular = () => {
  const [circularData, setCircularData] = useState(null);
  const [circularImageData, setCircularImageData] = useState([]);
  const [circularPdfData, setCircularPdfData] = useState([]);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/circular.php");
      if (response?.status === 200) {
        // setCircularData(response.data);
        setCircularImageData(
          ...circularImageData,
          response.data.filter((data) => {
            return data.pdf_path === null || data.pdf_path === undefined;
          })
        );
        setCircularPdfData(
          ...circularPdfData,
          response.data.filter((data) => {
            return data.image_path === null || data.image_path === undefined;
          })
        );
        console.log(response.data);
      }
    } catch (error) {
      setCircularData([]);
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  // if (circularData == null) {
  //   return (
  //     <>
  //       <h3>Loading.....</h3>
  //     </>
  //   );
  // }

  return (
    <>
      <CommonHeader heading={"Circular"} />
      <div className="container">
        {/* <div>
          {circularImageData.length > 0 &&
            circularImageData.map((data, index) => (
              <section key={index} className="bg-white">
                <div
                  id={`return${index}`}
                  className={
                    (index + 1) % 2 === 1
                      ? "section section-one"
                      : "section section-two"
                  }
                >
                  <div className={(index + 1) % 2 === 1 ? "box1" : "box2"}>
                    <h1>{data.title}</h1>
                    <p>{data.description}</p>
                  </div>
                  <div
                    className={
                      (index + 1) % 2 === 1
                        ? "box2 thumbnail-component"
                        : "box1 thumbnail-component"
                    }
                  >
                    <a href={`#${index}`}>
                      <img
                        className="thumbnail-image"
                        src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                        alt="Image description"
                      />
                      <div className="icon-container">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#30303090"
                            d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                          />
                        </svg>
                      </div>
                    </a>
                    <div id={index} className="overlay">
                      <a href={`#${index}`} className="overlay-background" />
                      <div className="overlay-content">
                        <img
                          className="overlay-image"
                          src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                          alt="image description"
                        />
                        <a href={`#return${index}`} className="close-button">
                          ✖
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))}
        </div> */}

        {/* <div>
          {circularImageData.length > 0 && (
            <section className="bg-white">
              {circularImageData.map((data, index) => (
                <section
                  key={index}
                  className={`section ${
                    index % 2 === 0 ? "section-one" : "section-two"
                  }`}
                >
                  <div className="box1 thumbnail-component">
                    <a href={`#${index}`}>
                      <img
                        className="thumbnail-image"
                        src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                        alt="Image description"
                      />
                      <div className="icon-container">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#30303090"
                            d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                          />
                        </svg>
                      </div>
                    </a>
                    <h1>{data.title}</h1>
                    <p>{data.description}</p>
                    <div id={index} className="overlay">
                      <a href={`#${index}`} className="overlay-background" />
                      <div className="overlay-content">
                        <img
                          className="overlay-image"
                          src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                          alt="image description"
                        />
                        <a href={`#return${index}`} className="close-button">
                          ✖
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              ))}
            </section>
          )}
        </div> */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: "1rem" }}>
            {circularImageData.length > 0 &&
              circularImageData.map((data, index) => (
                <>
                  <div className="col-md-6 content-center">
                    <div className="col-md-10">
                    <div className="thumbnail-component">
                      <a href={`#${index}`}>
                        <img
                          className="thumbnail-image"
                          src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                          alt="Image description"
                        />
                        <div className="icon-container">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="#30303090"
                              d="M16 0H9.5L12 2.5l-3 3L10.5 7l3-3L16 6.5zm0 16V9.5L13.5 12l-3-3L9 10.5l3 3L9.5 16zM0 16h6.5L4 13.5l3-3L5.5 9l-3 3L0 9.5zM0 0v6.5L2.5 4l3 3L7 5.5l-3-3L6.5 0z"
                            />
                          </svg>
                        </div>
                      </a>
                      <h1>{data.title}</h1>
                      <p>{data.description}</p>
                      <div id={index} className="overlay">
                        <a href={`#${index}`} className="overlay-background" />
                        <div className="overlay-content">
                          <img
                            className="overlay-image"
                            src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                            alt="image description"
                          />
                          <a href={`#return${index}`} className="close-button">
                            ✖
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>

        <div className="row">
          {circularPdfData.length > 0 &&
            circularPdfData.map((data, index) => {
              return (
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12 content-center">
                      <iframe
                        className="pdf-iframe"
                        src={axios.defaults.baseURL + "/PDFs/" + data.pdf_path}
                        title="PDF document"
                        frameBorder="0"
                        width="80%"
                        height="300px"
                      ></iframe>
                    </div>
                    <div className="col-md-12">
                      <h4
                        className="text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        {data.title}
                      </h4>
                    </div>
                    <div className="col-md-12">
                      <p className="text-center">
                        {data.description !== "" && data.description}
                      </p>
                    </div>
                    <div
                      className="col-md-12"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <a
                        href={axios.defaults.baseURL + "/PDFs/" + data.pdf_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success"
                      >
                        View PDF
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Circular;
