import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const ReferAfriend = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [refName, setRefName] = useState("");
  const [refEmail, setRefEmail] = useState("");
  const [refPhone, setRefPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const closing = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRefName("");
    setRefEmail("");
    setRefPhone("");
    setMessage("");
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsSaving(true);
    axios
      .post("/refral.php", {
        name: name,
        email: email,
        mobile: phone,
        ref_name: refName,
        ref_email: refEmail,
        ref_mobile: refPhone,
        message: message,
      })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Message sent successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsSaving(false);
          closing();
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An error occurred!",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsSaving(false);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <h1 className="text-center">Refer A Friend</h1>
      </div>
      <div className="row">
        <h5 className="text-center">
          Contact us for a quote, help, or to join the team.
        </h5>
      </div>
      <div className="row">
        <div className="col-md-2"></div>  
        <div className="col-md-3">
          <div className="card">
            <i className="fa fa-envelope text-primary"></i>
            <div className="card-body">
              <h5 className="card-title text-primary">Tulip road</h5>
              <p className="card-text text-primary">Gurgaon, Haryana</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <i className="fa fa-envelope text-primary"></i>
            <div className="card-body">
              <h5 className="card-title text-primary">Email:</h5>
              <p className="card-text text-primary">
                administration@ramakrishnapublicschool.com
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <i className="fa fa-phone text-primary"></i>
            <div className="card-body">
              <h5 className="card-title text-primary">Phone:</h5>
              <p className="card-text text-primary">+91 9643440055</p>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <form onSubmit={handleSave} method="post">
            <div className="form-group">
              <label htmlFor="name">Enter Your Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter Your Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Enter Your Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="number">Enter Your Number:</label>
              <input
                type="tel"
                className="form-control"
                id="number"
                placeholder="Enter Your Number"
                name="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="referralName">
                Enter Referral Person's Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="referralName"
                placeholder="Enter Referral Person's Name"
                name="referralName"
                value={refName}
                onChange={(e) => setRefName(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="referralEmail">
                Enter Referral Person's Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="referralEmail"
                placeholder="Enter Referral Person's Email"
                name="referralEmail"
                value={refEmail}
                onChange={(e) => setRefEmail(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="referralNumber">
                Enter Referral Person's Number:
              </label>
              <input
                type="tel"
                className="form-control"
                id="referralNumber"
                placeholder="Enter Referral Person's Number"
                name="referralNumber"
                value={refPhone}
                onChange={(e) => setRefPhone(e.target.value)}
                required // Field is required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message:</label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                placeholder="Write your message here"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required // Field is required
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSaving}
            >
              {isSaving ? "Sending..." : "Submit"}
            </button>
          </form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default ReferAfriend;
