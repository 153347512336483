import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CommonHeader from "../Common Header/CommonHeader";

const MoreExtraCourses = () => {
  const [newCoursesData, setNewCoursesData] = useState(null);
  const [upcomingCourseData, setUpcomingCourseData] = useState(null);
  const [existingCourseData, setExistingCourseData] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/courses.php");

      if (response?.status === 200) {
        const data = response.data;

        // Filter data based on course type
        const newCourses = data.filter((course) => course.course_type === 1);
        const upcomingCourses = data.filter(
          (course) => course.course_type === 3
        );
        const existingCourses = data.filter(
          (course) => course.course_type === 2
        );

        // Set state variables accordingly
        setNewCoursesData(newCourses);
        setUpcomingCourseData(upcomingCourses);
        setExistingCourseData(existingCourses);
      }
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };
  return (
    <>
      <CommonHeader heading={"All Courses"} />
      <div className="container">
        <section class="news-box top-margin">
          <div class="container">
            <h2 className="text-center">
              <span>New Courses</span>
            </h2>
            <div class="row">
              {newCoursesData !== null ? (
                newCoursesData.map((obj, ind) => {
                  return (
                    <div class="col-lg-4 col-md-4 col-sm-12" key={ind}>
                      <div class="newsBox">
                        <div class="thumbnail">
                          {obj.video_path !== null ? ( // Check if course type is 1 and video path is available
                            <figure>
                              <video width="300" height="200" controls>
                                <source
                                  src={`${axios.defaults.baseURL}/Videos/${obj.video_path}`}
                                  type="video/mp4"
                                />
                              </video>
                            </figure>
                          ) : obj.image_path !== null ? ( // Check if course type is 1 and image path is available
                            <figure>
                              <img
                                src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                                alt="Course"
                                width="300"
                                height="200"
                              />
                            </figure>
                          ) : (
                            <div>No image or video found</div> // Render placeholder message
                          )}
                          <div class="caption maxheight2">
                            <div class="box_inner">
                              <div class="box">
                                <p class="title">
                                  <h5>{obj.title}</h5>
                                </p>
                                <p>
                                  {obj.description
                                    .split(" ")
                                    .slice(0, 30)
                                    .join(" ")}
                                </p>
                                <p>
                                  <Link to={`/aboutcourse/${obj.id}`}>
                                    <em>Read More...</em>
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>Loading ....</span>
              )}
            </div>
          </div>
          <hr />
          <div class="container">
            <h2 className="text-center">
              <span>Upcoming Courses</span>
            </h2>
            <div class="row">
              {upcomingCourseData !== null ? (
                upcomingCourseData.map((obj, ind) => {
                  return (
                    <div class="col-lg-4 col-md-4 col-sm-12" key={ind}>
                      <div class="newsBox">
                        <div class="thumbnail">
                          {obj.video_path !== null ? ( // Check if course type is 1 and video path is available
                            <figure>
                              <video width="300" height="200" controls>
                                <source
                                  src={`${axios.defaults.baseURL}/Videos/${obj.video_path}`}
                                  type="video/mp4"
                                />
                              </video>
                            </figure>
                          ) : obj.image_path !== null ? ( // Check if course type is 1 and image path is available
                            <figure>
                              <img
                                src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                                alt="Course"
                                width="300"
                                height="200"
                              />
                            </figure>
                          ) : (
                            <div>No image or video found</div> // Render placeholder message
                          )}
                          <div class="caption maxheight2">
                            <div class="box_inner">
                              <div class="box">
                                <p class="title">
                                  <h5>{obj.title}</h5>
                                </p>
                                <p>
                                  {obj.description
                                    .split(" ")
                                    .slice(0, 30)
                                    .join(" ")}
                                </p>
                                <p>
                                  <Link to={`/aboutcourse/${obj.id}`}>
                                    <em>Read More...</em>
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>Loading ....</span>
              )}
            </div>
          </div>
          <hr />
          <div class="container">
            <h2  className="text-center">
              <span>Existing Courses</span>
            </h2>
            <div class="row">
              {existingCourseData !== null ? (
                existingCourseData.map((obj, ind) => {
                  return (
                    <div class="col-lg-4 col-md-4 col-sm-12" key={ind}>
                      <div class="newsBox">
                        <div class="thumbnail">
                          {obj.course_type === 1 && obj.video_path ? ( // Check if course type is 1 and video path is available
                            <figure>
                              <video width="300" height="200" controls>
                                <source
                                  src={`${axios.defaults.baseURL}/Videos/${obj.video_path}`}
                                  type="video/mp4"
                                />
                              </video>
                            </figure>
                          ) : obj.course_type === 1 && obj.image_path ? ( // Check if course type is 1 and image path is available
                            <figure>
                              <img
                                src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                                alt="Course"
                                width="300"
                                height="200"
                              />
                            </figure>
                          ) : (
                            <div>No image or video found</div> // Render placeholder message
                          )}
                          <div class="caption maxheight2">
                            <div class="box_inner">
                              <div class="box">
                                <p class="title">
                                  <h5>{obj.title}</h5>
                                </p>
                                <p>
                                  {obj.description
                                    .split(" ")
                                    .slice(0, 30)
                                    .join(" ")}
                                </p>
                                <p>
                                  <Link to={`/aboutcourse/${obj.id}`}>
                                    <em>Read More...</em>
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span>No Existing Course Found....</span>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MoreExtraCourses;
