import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";
import './gallery.css';

const Gallery = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEvent, setSelectedEvent] = useState("");
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    gettingData();
  }, []);

  useEffect(() => {
    filterData();
  }, [selectedYear, selectedEvent, galleryData]);

  const gettingData = async () => {
    try {
      const response = await axios.get("/schoolGallery.php");
      if (response?.status === 200) {
        setGalleryData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterData = () => {
    if (galleryData.length > 0) {
      let filtered = galleryData.filter((item) => {
        return (
          item.year === selectedYear &&
          (selectedEvent === "" || item.title === selectedEvent)
        );
      });
      setFilteredData(filtered);
    }
  };

  const getUniqueValues = (key) => {
    return [...new Set(galleryData.map((item) => item[key]))];
  };

  const years = galleryData ? getUniqueValues("year") : [];
  const events = galleryData ? getUniqueValues("title") : [];

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  return (
    <>
      <CommonHeader heading={"Our Gallery"} />
      <div className="container gallerySection">
        {/* Filter Dropdowns */}
        <div className="row">
          <div className="col-md-12 text-center mb-3">
            <div className="row" style={{ marginTop: "2rem" }}>
              <div className="col-sm-6">
                {/* Dropdown for selecting year */}
                <select
                  className="form-control"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                >
                  <option value="">All Years</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                {/* Dropdown for selecting event name */}
                <select
                  className="form-control"
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  <option value="">All Events</option>
                  {events.map((event) => (
                    <option key={event} value={event}>
                      {event}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Gallery Content */}
        {filteredData.map((eventItem) => (
          <div key={eventItem.id} className="row mb-4">
            <div className="col-md-12">
              {/* Event title */}
              <h2 className="text-center upercaseTitle">{eventItem.title}</h2>
              {/* Event description */}
              <div>
                <p>{renderContent(eventItem.description)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 topmargin">
                <div className="row">
                  <div
                    className="portfolio-items isotopeWrapper clearfix"
                    id="3"
                  >
                    {eventItem.images.map((image, index) => (
                      <article
                        className="col-sm-4 isotopeItem webdesign"
                        key={index}
                      >
                        <div className="portfolio-item">
                          <img
                            src={axios.defaults.baseURL + "/Images/" + image}
                            style={{
                              width: "80%",
                              height: "30rem",
                              objectFit: "cover",
                              border: "2px solid grey",
                            }}
                            alt="Image Cannot Be Loaded"
                            className="imageShadow"
                          />
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Gallery;
