import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [schoolDetail,setSchoolDetail] = useState(null);

  const closing = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  useEffect(()=>{
    gettingSchoolDetail();
  },[]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsSaving(true);
    axios
      .post("/contactUs.php", {
        name: name,
        email: email,
        mobile: phone,
        message: message,
      })
      .then(function (response) {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Message sent successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsSaving(false);
          closing();
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An error occurred!",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsSaving(false);
      });
  };

  const gettingSchoolDetail = async () => {
    let response = await axios.get("/schoolDetail.php");

    if (response?.status == 200) {
      setSchoolDetail(response.data[0]);
    }
  };

  // const renderContent = (content) => {
  //   return content
  //     .split("\n")
  //     .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  // };

  return (
    <>
      {/* container */}
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3 className="section-title">Contact Us</h3>
            <p>
              Have a question or need assistance? Feel free to reach out to us
              using the contact information below!
            </p>

            <form
              className="form-light mt-20"
              role="form"
              onSubmit={handleSave}
            >
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Write your message here..."
                  style={{ height: "100px" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-two" disabled={isSaving}>
                {isSaving ? "Sending..." : "Send message"}
              </button>
              <p>
                <br />
              </p>
            </form>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">
                <h3 className="section-title">School Address</h3>
                <div className="contact-info">
                  <h5>Address</h5>
                  <p>
                  {schoolDetail !== null && schoolDetail.address}
                  </p>

                  <h5>Email</h5>
                  <p>{schoolDetail !== null && schoolDetail.email}</p>

                  <h5>Phone</h5>
                  <p>{schoolDetail !== null && schoolDetail.contact}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /container */}
    </>
  );
};

export default Contact;
