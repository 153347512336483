import React, { useState, useEffect } from "react";
import axios from "axios";
import "./facilities.css";
import CommonHeader from "../Common Header/CommonHeader";

const Facilities = () => {
  const [facilityData, setFacilityData] = useState([]);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      const data = response.data;
      const facility = data.filter((obj) => obj.content_type === 3);
      setFacilityData(facility);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  return (
    <>
      <CommonHeader heading={"Facilities"} />
      <div className="container">
        <section class="container">
          <div class="row">
            {facilityData !== null ? (
              facilityData.map((obj, ind) => {
                return (
                  <section class="col-sm-12 maincontent" key={ind}>
                    <h3>{obj.title}</h3>
                    <p>
                      <img
                        src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                        alt="No Image Data Found"
                        class={`img-rounded ${
                          ind % 2 === 0 ? "pull-right" : "pull-left"
                        }`}
                        width="300"
                        height={300}
                      />
                      {renderContent(obj.description)}
                    </p>
                  </section>
                );
              })
            ) : (
              <>
                <h3>Loading...</h3>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Facilities;
