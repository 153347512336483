import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ExtraFeature = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      // console.log(response.data);
      let filteredData = response.data.filter((obj) => {
        return obj.content_type === 5 && obj;
      });
      setData(filteredData.slice(0, 4));
    }
  };

  return (
    <>
      <div
        className="container"
        style={{ position: "relative", marginTop: "-20rem", zIndex: "1" }}
      >
        <div className="row">
          {data !== null ? (
            data.map((obj, ind) => (
              <div className="col-md-3" key={ind}>
                <div className="grey-box-icon">
                  <div className="icon-box-top grey-box-icon-pos">
                    <img
                      src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                      alt="No Image"
                      height={140}
                      width={140}
                      style={{borderRadius:'50%'}}
                    />
                  </div>
                  <h4>{obj.title}</h4>
                  {obj.description ? (
                    <p>
                      {obj.description.split(" ").slice(0, 30).join(" ")}...
                    </p>
                  ) : (
                    <p>No description available</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <>
              <span>Loading....</span>
            </>
          )}
          <div className="col-md-12 text-right">
            <Link to="/moreextrafeature">
              <em>Know More...</em>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraFeature;
