import React, { useState, useEffect } from "react";
import axios from "axios";
import "./facilities.css";
import CommonHeader from "../Common Header/CommonHeader";

const SchoolTour = (props) => {
  const { mmid, smid } = props;
  const [schoolTourData, setSchoolTourData] = useState(null);

  // useEffect(() => {
  //   gettingData();
  // }, []);

  // const gettingData = async () => {
  //   try {
  //     const response = await axios.get("/video.php");
  //     if (response?.status === 200) {
  //       const filteredData = response.data.filter(
  //         (obj) => obj.main_menu_id === mmid && obj.sub_menu_id === smid
  //       );
  //       setSchoolTourData(filteredData[0]);
  //       // console.log(filteredData);
  //     }
  //   } catch (error) {
  //     setSchoolTourData([]);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // Render loading indicator if data is not yet available
  // if (!schoolTourData) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <CommonHeader heading={"School Tour"} />
      {/* <div
        className="container"
        style={{ padding: "20px" }}
      >
        <div className="row">
          <div className="col-xs-12 heading">
            {schoolTourData.video_path == "" ||
            schoolTourData.video_path == null ? (
              "No Video Found"
            ) : (
              <video
                className="img-responsive"
                width="100%"
                height="auto"
                controls
                style={{borderRadius:"10px"}}
              >
                <source
                  src={`${axios.defaults.baseURL}/Videos/${schoolTourData.video_path}`}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>
      </div> */}

      <div className="container" style={{ padding: "20px" }}>
        <div className="row mt-4">
          <div className="col-12">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/bLlOS5pQE1Y?autoplay=0`}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolTour;
