import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";

const CoCurricularInitiative = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/coCurricular.php");
      if (response?.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      setData([]);
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  if (data === null) {
    return (
      <>
        <h2>Loading......</h2>
      </>
    );
  }
  return (
    <>
      <CommonHeader heading={"Co Curricular Initiative"} />
      <div className="container">
        <section className="container">
          <div className="row">
            {data !== null ? (
              data.map((obj, ind) => (
                <section className="col-sm-12 maincontent" key={ind}>
                  <h3>{obj.title}</h3>
                  <p>
                    {obj.image_path && ( // Check if image_path exists
                      <img
                        src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                        alt="No Image Data Found"
                        className={`img-rounded ${
                          ind % 2 === 0 ? "pull-right" : "pull-left"
                        }`}
                        width="300"
                        height={300}
                      />
                    )}
                    {renderContent(obj.description)}
                  </p>
                </section>
              ))
            ) : (
              <h3>Loading...</h3>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CoCurricularInitiative;
