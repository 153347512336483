import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";
import { useParams } from "react-router-dom";
import './news.css';

const AboutNewsEvents = () => {
  const { head } = useParams(); // Destructuring the useParams object directly
  const [about, setAbout] = useState([]);

  useEffect(() => {
    gettingData();
  }, [head]);

  const gettingData = async () => {
    try {
      const response = await axios.get("/newsEvents.php");
      if (response?.status === 200) {
        const data = response.data;
        const abouData = data.filter(
          (obj) => obj.title.trim().toLowerCase() === head.toLowerCase()
        );
        setAbout(abouData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  if (about.length === 0) {
    return (
      <>
        <h3>Loading...</h3>
      </>
    );
  }

  return (
    <>
      <CommonHeader heading={head} />
      <div className="container" style={{marginTop:"2rem"}}>
        <div className="row">
          <section className="col-md-12 col-sm-12 maincontent">
            {about.map((event, index) => (
              <div key={index}>
                <div className="row">
                  {event.images.map((image, imgIndex) => (
                    <div className="col-md-4" key={imgIndex}>
                      <img
                        src={`${axios.defaults.baseURL}/Images/${image}`}
                        alt="Event"
                        width={300}
                        height={300}
                        className="imageShadow"
                      />
                    </div>
                  ))}
                </div>
                <div className="row" style={{marginTop:"2rem"}}>
                  <div className="text-center">{renderContent(event.description)}</div>
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutNewsEvents;
