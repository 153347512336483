import React, { useState } from "react";
import "./admission.css";
import Swal from "sweetalert2";
import axios from "axios";
import CommonHeader from "../Common Header/CommonHeader";

const AdmissionForm = () => {
  const [formData, setFormData] = useState({
    class: "",
    we: "",
    _and: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    aadharCardNo: "",
    bloodGroup: "",
    religion: "",
    caste: "",
    nationality: "",
    community: "",
    residentialAddress: "",
    correspondenceAddress: "",
    fatherName: "",
    fatherQualification: "",
    fatherOccupation: "",
    fatherAnnualIncome: "",
    fatherContact: "",
    fatherEmail: "",
    motherName: "",
    motherQualification: "",
    motherOccupation: "",
    motherAnnualIncome: "",
    motherContact: "",
    motherEmail: "",
    birthCertificateCheck: false,
    adharCardCheck: false,
    passportPhotoCheck: false,
    birthCertificate: null,
    adharCard: null,
    passportPhoto: null,
    date: "",
    signature: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [selectedClassType, setSelectedClassType] = useState("");

  const classTypes = [
    { name: "Pre Primary Wing", grades: ["Play group", "Nursery", "K.G"] },
    {
      name: "Primary Wing",
      grades: ["I", "II", "III", "IV", "V"],
    },
    { name: "Middle School", grades: ["VI", "VII", "VIII"] },
    { name: "Secondary School", grades: ["IX", "X"] },
  ];

  //for setting the class according to the group
  const handleClassTypeChange = (event) => {
    setSelectedClassType(event.target.value);
    // console.log(classTypes.some(classType => classType.name === selectedClassType));
  };

  //for setting the data inside a form
  const handleChange = (event) => {
    if (event.target.type === "file") {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
    // console.log(formData);
  };

  const closing = () => {
    setFormData({
      class: "",
      we: "",
      _and: "",
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      aadharCardNo: "",
      bloodGroup: "",
      religion: "",
      caste: "",
      nationality: "",
      community: "",
      residentialAddress: "",
      correspondenceAddress: "",
      fatherName: "",
      fatherQualification: "",
      fatherOccupation: "",
      fatherAnnualIncome: "",
      fatherContact: "",
      fatherEmail: "",
      motherName: "",
      motherQualification: "",
      motherOccupation: "",
      motherAnnualIncome: "",
      motherContact: "",
      motherEmail: "",
      birthCertificate: null,
      adharCard: null,
      passportPhoto: null,
      date: "",
      signature: null,
    });
    document.getElementById("admissionForm").reset();
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsSaving(true);

    const newFormDataObject = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        if (value instanceof File) {
          newFormDataObject.append(key, value);
        } else {
          newFormDataObject.append(key, String(value));
        }
      }
    }

    newFormDataObject.append("operationType", "add");

    // for (var pair of newFormDataObject.entries()) {
    //   console.log(pair[0] + ": " + pair[1]);
    // }

    axios
      .post("/admissionForm.php", newFormDataObject)
      .then(function (response) {
        if (response && response?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Admission form submitted successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setIsSaving(false);
          closing();
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "An error occurred!",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsSaving(false);
      });
  };

  return (
    <>
      <CommonHeader heading={"ADMISSION FORM"} />
      <form method="post" onSubmit={handleSave} id="admissionForm">
        <div className="container">
          {/* //admission for */}
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-md-4">
              <label>
                Admission required for:- <b className="text-danger">*</b>
              </label>
            </div>
            <div className="col-md-8">
              <div
                className="btn-group centerlizewithequalspacerowwise"
                data-toggle="buttons"
              >
                {classTypes.map((classType, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={classType.name.split(" ").join("")}
                      name="classType"
                      value={classType.name}
                      checked={selectedClassType === classTypes.name}
                      onChange={handleClassTypeChange}
                    />
                    <label htmlFor={classType.name.split(" ").join("")}>
                      {classType.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 text-center">
              {selectedClassType && (
                <select
                  className="form-control"
                  onChange={handleChange}
                  name="class"
                >
                  <option value="">Select {selectedClassType}</option>
                  {classTypes
                    .find((classType) => classType.name === selectedClassType)
                    ?.grades.map((grade, idx) => (
                      <option key={idx} value={grade}>
                        {grade}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </div>

          {/* we and */}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  We <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.we}
                  onChange={handleChange}
                  required
                  name="we"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  And <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData._and}
                  onChange={handleChange}
                  required
                  name="_and"
                />
              </div>
            </div>
          </div>

          {/* information of child */}
          <div className="row">
            <h4 className="text-danger">A. INFORMATION OF CHILD</h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  FirstName <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  name="firstName"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>MiddleName</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.middleName}
                  onChange={handleChange}
                  name="middleName"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>LastName</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.lastName}
                  onChange={handleChange}
                  name="lastName"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Gender <b className="text-danger">*</b>
                </label>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="male"
                        value="male"
                        checked={formData.gender === "male"}
                        onChange={handleChange}
                        name="gender"
                        required
                      />
                      <label className="form-check-label" htmlFor="male">
                        Male
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="female"
                        value="female"
                        checked={formData.gender === "female"}
                        onChange={handleChange}
                        name="gender"
                        required
                      />
                      <label className="form-check-label" htmlFor="female">
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="other"
                        value="other"
                        checked={formData.gender === "other"}
                        onChange={handleChange}
                        name="gender"
                        required
                      />
                      <label className="form-check-label" htmlFor="other">
                        Other
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Date Of Birth <b className="text-danger">*</b>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                  name="dateOfBirth"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Aadhar card no. <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.aadharCardNo}
                  onChange={handleChange}
                  required
                  maxLength={12}
                  name="aadharCardNo"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  BloodGroup <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.bloodGroup}
                  onChange={handleChange}
                  required
                  name="bloodGroup"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Religion <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.religion}
                  onChange={handleChange}
                  required
                  name="religion"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Caste <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.caste}
                  onChange={handleChange}
                  required
                  name="caste"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Nationality <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                  name="nationality"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Community <b className="text-danger">*</b>
                </label>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="scst"
                        value="SC/ST"
                        checked={formData.community === "SC/ST"}
                        onChange={handleChange}
                        name="community"
                        required
                      />
                      <label className="form-check-label" htmlFor="scst">
                        SC/ST
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="obc"
                        value="OBC"
                        checked={formData.community === "OBC"}
                        onChange={handleChange}
                        name="community"
                        required
                      />
                      <label className="form-check-label" htmlFor="obc">
                        OBC
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="gen"
                        value="General"
                        checked={formData.community === "General"}
                        onChange={handleChange}
                        name="community"
                        required
                      />
                      <label className="form-check-label" htmlFor="gen">
                        General
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="otherCommunity"
                        value="Other"
                        checked={formData.community === "Other"}
                        onChange={handleChange}
                        name="community"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="otherCommunity"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Residential Address <b className="text-danger">*</b>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={formData.residentialAddress}
                  onChange={handleChange}
                  required
                  name="residentialAddress"
                  rows={3}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Correspondence Address<b className="text-danger">*</b>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={formData.correspondenceAddress}
                  onChange={handleChange}
                  required
                  name="correspondenceAddress"
                  rows={3}
                />
              </div>
            </div>
          </div>

          {/* information of family */}
          <div className="row">
            <h4 className="text-danger">B. INFORMATION OF FAMILY</h4>
          </div>

          <div className="row">
            <div className="col-md-6">
              <u>
                <h5 className="text-danger">Father/Guardian :</h5>
              </u>
            </div>
            <div className="col-md-6">
              <u>
                <h5 className="text-danger">Mother/Guardian :</h5>
              </u>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Name <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.fatherName}
                  onChange={handleChange}
                  name="fatherName"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Name <b className="text-danger">*</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.motherName}
                  onChange={handleChange}
                  name="motherName"
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Educational Qualification</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.fatherQualification}
                  onChange={handleChange}
                  name="fatherQualification"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Educational Qualification</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.motherQualification}
                  onChange={handleChange}
                  name="motherQualification"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.fatherOccupation}
                  onChange={handleChange}
                  name="fatherOccupation"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.motherOccupation}
                  onChange={handleChange}
                  name="motherOccupation"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Annual Income</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.fatherAnnualIncome}
                  onChange={handleChange}
                  name="fatherAnnualIncome"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Annual Income</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.motherAnnualIncome}
                  onChange={handleChange}
                  name="motherAnnualIncome"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Father's Mobile No. <b className="text-danger">*</b>
                </label>
                <input
                  type="tel"
                  className="form-control"
                  value={formData.fatherContact}
                  onChange={handleChange}
                  name="fatherContact"
                  pattern="[0-9]{10}"
                  required
                  title="Please enter valid mobile no."
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Mother's Mobile No.</label>
                <input
                  type="tel"
                  className="form-control"
                  value={formData.motherContact}
                  onChange={handleChange}
                  name="motherContact"
                  pattern="[0-9]{10}"
                  required
                  title="Please enter valid mobile no."
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Email Id</label>
                <input
                  type="email"
                  className="form-control"
                  value={formData.fatherEmail}
                  onChange={handleChange}
                  name="fatherEmail"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email Id</label>
                <input
                  type="email"
                  className="form-control"
                  value={formData.motherEmail}
                  onChange={handleChange}
                  name="motherEmail"
                />
              </div>
            </div>
          </div>

          {/* enclosures*/}
          <div className="row">
            <h4 className="text-danger">C. ENCLOSURES</h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  onChange={handleChange}
                  className="form-check"
                  name="birthCertificateCheck"
                  value={true}
                  required
                />
                Birth Certificate. <b className="text-danger">*</b>
              </label>
              <input
                type="file"
                accept="application/pdf"
                name="birthCertificate"
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>

            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      adharCardCheck: !formData.adharCardCheck,
                    })
                  }
                  className="form-check"
                  name="adharCardCheck"
                  checked={formData.adharCardCheck}
                />
                Aadhar Card of Parent & Child
              </label>
              {formData.adharCardCheck && (
                <input
                  type="file"
                  accept="application/pdf"
                  name="adharCard"
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              )}
            </div>

            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  onChange={() =>
                    setFormData({
                      ...formData,
                      passportPhotoCheck: !formData.passportPhotoCheck,
                    })
                  }
                  className="form-check"
                  name="passportPhotoCheck"
                  checked={formData.passportPhotoCheck}
                />
                Passport Photo of Parent & Child
              </label>
              {formData.passportPhotoCheck && (
                <input
                  type="file"
                  accept="application/pdf"
                  name="passportPhoto"
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              )}
            </div>
          </div>

          <div className="row top-margin">
            <div className="col-md-4">
              <label for="date">Select Date</label>
              <input
                type="date"
                accept="application/pdf"
                name="date"
                onChange={handleChange}
                className="form-control"
                id="date"
                required
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <label for="signature">Signature Photo</label>
              <input
                type="file"
                accept="application/pdf"
                name="signature"
                onChange={handleChange}
                className="form-control"
                id="signature"
                required
              />
            </div>
          </div>

          <div className="row top-margin">
            <div className="col-md-12 centerlizewithequalspacerowwise">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSaving}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AdmissionForm;
