import React, { useEffect, useState } from "react";
import "./Marquee.css";
import axios from "axios";
import Swal from "sweetalert2";

const Marquee = () => {
  const [latestData, setLatestData] = useState("");

  useEffect(() => {
    gettingData();
    
  });

  const gettingData = async () => {
    let response = await axios.get("/latestUpdate.php");

    if (response?.status === 200) {
      //   console.log(response.data[0].text_name);
      setLatestData(response.data[0].text_name);
    }
  };

  return (
    <>
      <div className="marquee-container">
        <div className="marquee">{latestData}</div>
      </div>
    </>
  );
};

export default Marquee;
