import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VideoSection from "./VideoSection";
import ExtraFeature from "./ExtraFeature";
import Slider from "./Slider";
import NewCourse from "./NewCourse";
import AboutUs from "./AboutUs";
import Swal from "sweetalert2";

const MainSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the alert has been shown before by checking localStorage
    if (!localStorage.getItem("admissionAlertShown")) {
      // Show the SweetAlert when the website loads for the first time
      Swal.fire({
        title: "Admission Started For 2025",
        text: "For admission info, contact us at our support page.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Go to Contact",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          // After the user interacts, mark the alert as shown in localStorage
          localStorage.setItem("admissionAlertShown", "true");
          // If "Go to Contact" is clicked, navigate to the contact page
          navigate("/contact1");
        }
      });
    }
  }, [navigate]);

  return (
    <>
      {/* <VideoSection /> */}
      <Slider />
      <ExtraFeature />
      <NewCourse />
      <AboutUs />
    </>
  );
};

export default MainSection;
