import React, { useState, useEffect } from "react";
import CommonHeader from "../Common Header/CommonHeader";
import axios from "axios";
import "./admission.css";

const AdmissionProcess = () => {
  const [admissionProcesses, setAdmissionProcesses] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    try {
      const response = await axios.get("/admissionProcess.php");
      if (response?.status === 200) {
        setAdmissionProcesses(response.data);
      }
    } catch (error) {
      setAdmissionProcesses([]);
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  if (!admissionProcesses) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CommonHeader heading={"Admission Process"} />
      <div className="container admission-container">
        {admissionProcesses.map((process, index) =>
          process.class_info !== null ? (
            <div className="row admission-process" key={index}>
              <div className="col-xs-12">
                <u>
                  <h2>{process.title}</h2>
                </u>
              </div>
              <div className="col-xs-12 col-md-6">
                <h3>{process.class_info}</h3>
              </div>
              <div className="col-xs-12 col-md-6">
                {renderContent(process.content)}
              </div>
            </div>
          ) : (
            <div className="row" key={index}>
              <div className="col-xs-12 col-lg-12 col-md-12 text-center">
                <h2>{process.title}</h2>
              </div>
              <div className="col-xs-12 col-md-12">
                {renderContent(process.content)}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default AdmissionProcess;
