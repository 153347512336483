import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const VideoSection = () => {
  const [videoData, setVideoData] = useState(null);

  useEffect(() => {
    gettingData();
  });

  const gettingData = async () => {
    let response = await axios.get("/video.php");

    if (response?.status === 200) {
        console.log(response.data);
      setVideoData(response.data[0]);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="row text-center">
              <div className="col-lg-12 col-md-12">
                <h3>This is sample testing</h3>
              </div>
              <div className="col-lg-12 col-md-12">
                <p>
                  loremcjhvdsb vjhdsbchjbdscjbdskjcbndskjcndscjbds
                  cdsjchbdsjcbdsjbchcbhjdscvkdsjbnvjkdsbnvkjdsbnvkjbds
                  dcvjkdsbckjdsbcvkjdsbvkjdsbvkjbdskvjbdskvbdskjvbdsjkv
                  cvdsjkcbdskjjvbcdsjkbvkdsjbvkjdsbvkjdsbvkjbjdsvkbdsvjkdsb
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="text-center">
              <video width="500" height="300" controls>
                <source
                  src={`${axios.defaults.baseURL}/Videos/${videoData.video_path}`}
                  type="video/mp4"
                />
              </video>
              {/* {menus.video_path == "" || menus.video_path == null ? (
                "No Video Found"
              ) : (
                <video width="200" height="100" controls>
                  <source
                    src={`${axios.defaults.baseURL}/Videos/${menus.video_path}`}
                    type="video/mp4"
                  />
                </video>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSection;
