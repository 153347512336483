import React, { useState, useEffect } from "react";
import CommonHeader from "../Common Header/CommonHeader";
import { useParams } from "react-router-dom";
import axios from "axios";

const AboutCourse = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    gettingData();
  }, [id]);

  const gettingData = async () => {
    try {
      const response = await axios.get("/courses.php?id=" + id);

      if (response?.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };

  if (data === null) {
    return <h1>Loading....</h1>; // Added return statement
  }

  return (
    <>
      <CommonHeader heading={data.title} />
      <div className="container">
        <section class="container">
          <div class="row">
            <section class="col-sm-12 maincontent">
              <h3>{data.title}</h3>
              <p>
                {data.video_path !== null ? (
                  <video width="300" height="200" controls class="pull-right">
                    <source
                      src={`${axios.defaults.baseURL}/Videos/${data.video_path}`}
                      type="video/mp4"
                    />
                  </video>
                ) : data.image_path !== null ? (
                  <img
                    src={`${axios.defaults.baseURL}/Images/${data.image_path}`}
                    alt="No Image Data Found"
                    class="img-rounded pull-right"
                    width="300"
                  />
                ) : (
                  <div>No image or video found</div>
                )}

                {renderContent(data.description)}
              </p>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutCourse;
