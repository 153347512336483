import React, { useState, useEffect } from "react";
import axios from "axios";
import "./about.css";

const AboutUs = () => {
  const [founder, setFounder] = useState(null);
  const [leader, setLeader] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      const data = response.data;
      const founderData = data.filter((obj) => obj.content_type === 1);
      const leaderData = data.filter((obj) => obj.content_type === 2);

      setFounder(founderData);
      setLeader(leaderData);
    }
  };

  return (
    <>
      <hr />
      <section className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-box clearfix ">
              <h2 className="title-box_primary text-center">
                <b>Our Founder</b>
              </h2>
            </div>
            {founder !== null ? (
              founder.map((obj, ind) => (
                <div className="col-lg-6 col-md-6 col-sm-12" key={ind}>
                  <div className="newsBox">
                    <div className="thumbnail">
                      <figure className="center">
                        <img
                          width="250"
                          height="400"
                          src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                          alt="No Image Found"
                        />
                      </figure>
                      <div className="caption maxheight2">
                        <div className="box_inner">
                          <div className="box">
                            <p className="title">
                              <h4 className="text-center">
                                <b>{obj.title}</b>
                              </h4>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h3>Loading...</h3>
            )}
          </div>

          <div className="col-md-12">
            <div className="title-box clearfix ">
              <h2 className="title-box_primary text-center">
                <b>Our Leaders</b>
              </h2>
            </div>
            {leader !== null ? (
              leader.map((obj, ind) => (
                <div className="col-lg-6 col-md-6 col-sm-12" key={ind}>
                  <div className="newsBox">
                    <div className="thumbnail">
                      <figure className="center">
                        <img
                          width="300"
                          height="400"
                          src={`${axios.defaults.baseURL}/Images/${obj.image_path}`}
                          alt="No Image Found"
                        />
                      </figure>
                      <div className="caption maxheight2">
                        <div className="box_inner">
                          <div className="box">
                            <p className="title">
                              <h4 className="text-left">
                                <b>
                                  {(() => {
                                    const titleParts = obj.title.split(" (");
                                    return (
                                      <>
                                        {titleParts[0]}
                                        <br />({titleParts[1]}
                                      </>
                                    );
                                  })()}
                                </b>
                              </h4>
                            </p>
                            {obj.description ? (
                              <p>{obj.description}</p>
                            ) : (
                              <p>No description available</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h3>Loading...</h3>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
