import React from "react";

const CommonHeader = (props) => {
  return (
    <header id="head" class="secondary">
      <div class="container">
        <h1>{props.heading}</h1>
      </div>
    </header>
  );
};

export default CommonHeader;
