import React, { useState, useEffect } from "react";
import CommonHeader from "../Common Header/CommonHeader";
import { Link } from "react-router-dom";
import axios from "axios";
import { render } from "@testing-library/react";

const MoreExtraFeature = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    gettingData();
  }, []);

  const gettingData = async () => {
    let response = await axios.get("/homeContent.php");

    if (response?.status === 200) {
      //   console.log(response.data);
      setData(response.data);
    }
  };

  const renderContent = (content) => {
    return content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  };
  return (
    <>
      <CommonHeader heading={"Extra Features"} />
      <div className="container">
        <div id="courses">
          <section class="container">
            <h3>Extra Features</h3>
            <div class="row">
              {data !== null ? (
                data.map((obj, ind) =>
                  obj.content_type === 5 ? (
                    <div class="col-md-4">
                      <div class="featured-box">
                        <div class="text">
                          <h3>{obj.title}</h3>
                          {obj.description.split(' ').slice(0, 30).join(' ')}
                          <p>
                            <Link to={`/aboutextrafeature/${obj.id}`}>
                              <em>Read More..</em>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <>
                  <span>Loading....</span>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default MoreExtraFeature;
